import React, { useEffect } from 'react'
import Image from '@/components/base/image'
import { AsgInfoBarProps } from './types'
import { Column, Row } from '@/components/base/gridview'
import { useWidth } from '@/core/hooks/useWidthResize'
import { useApp } from '@/core/contexts/app'
import Anchor from '@/components/base/anchor/Anchor'

const AsgInfoBar: React.FunctionComponent<AsgInfoBarProps> = (props) => {
  const width = useWidth()
  const app = useApp()

  const TextContent = () => {
    return (
      <Column
        xs={{ size: 12, order: 'first' }}
        sm={{ size: 12, order: 'first' }}
        xl={{ size: 8, order: props.isReverse ? 'last' : 'first' }}
        className="left"
      >
        <span
          style={{ textAlign: props.isReverse ? 'right' : 'left' }}
          className="group-title"
        >
          {props.title}
        </span>
        <p style={{ textAlign: props.isReverse ? 'right' : 'left' }}>
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </p>
        {/* {width > 768 && (
            <>
              <span className="firms-title">
                {app.settings.translations['ourCompanies']}
              </span>
              <div className="logo-wrapper">
                {props.logos?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Anchor href={item.anchor?.href}>
                        <Image alt={item.title} {...item.image} />
                      </Anchor>
                    </div>
                  )
                })}
              </div>
            </>

          )}*/}
        <Anchor {...props.link} className="button">
          {app.settings.translations['read-more']}
        </Anchor>
      </Column>
    )
  }
  const ImageContent = () => {
    return (
      <Column
        xs={{ size: 12, order: 'last' }}
        sm={{ size: 12, order: 'last' }}
        xl={{
          size: 4,
          order: props.isReverse ? 'first' : 'last',
          align: 'center',
        }}
        className="right"
      >
        <Anchor {...props.link}>
          <Image alt="" {...props.image} />
        </Anchor>
      </Column>
    )
  }

  return (
    <>
      <Row
        className={`info-bar-wrapper ${props.isReverse && 'reverse'}`}
        gutter="md"
        id={props.title}
      >
        <React.Fragment>
          <TextContent />
          <ImageContent />
        </React.Fragment>
      </Row>
      {/* {width <= 768 && (
        <Row
          className={`info-bar-wrapper ${props.isReverse && 'reverse'}`}
          gutter="md"
          style={{
            borderBottom: '1px solid #ccc',
            paddingBottom: '40px',
            marginBottom: '20px',
          }}
        >
          <Column
            xs={{ size: 12, order: 'first' }}
            sm={{ size: 12, order: 'first' }}
            xl={{ size: 5, order: props.isReverse ? 'last' : 'first' }}
            className="left"
            style={{ paddingTop: 0 }}
          >
            <span className="firms-title">
              {app.settings.translations['ourCompanies']}
            </span>
            <div className="logo-wrapper">
              {props.logos?.map((item, index) => (
                <div key={index}>
                  <Anchor href={item.anchor?.href}>
                    <Image alt={item.title} {...item.image} />
                  </Anchor>
                </div>
              ))}
            </div>
          </Column>
        </Row>
      )} */}
    </>
  )
}

export default AsgInfoBar
