import React, { useEffect, useState } from 'react'
import { AsgInfoBarListProps } from './types'
import AsgInfoBar from '@/components/widgets/asg-info-bar'
import { AsgInfoBarProps } from '../asg-info-bar/types'

const AsgInfoBarList: React.FunctionComponent<AsgInfoBarListProps> = ({
  data = [],
}) => {
  const [sortedData, setSortedData] = useState<null | AsgInfoBarProps[]>(null)

  useEffect(() => {
    const sortDataByTitle = () => {
      const sortedData = data.sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
      return sortedData
    }

    const sortedData = sortDataByTitle()
    setSortedData(sortedData)
  }, [data])

  return (
    <React.Fragment>
      {sortedData &&
        sortedData.map((item, index) => (
          <AsgInfoBar key={index} {...item} isReverse={index % 2 !== 0} />
        ))}
    </React.Fragment>
  )
}

export default AsgInfoBarList
